var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "borderColor borderColorBackground mb-3"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center flex-wrap mb-3"
  }, [_c('div', {
    staticClass: "mt-3 d-flex align-items-center"
  }, [_vm._v(" 選擇 INV : "), _c('b-form-select', {
    staticClass: "invSelection ml-2 mr-3",
    attrs: {
      "options": _vm.selectOptions,
      "size": "sm"
    },
    model: {
      value: _vm.tableQueryParams.tableQuerySelectNum,
      callback: function callback($$v) {
        _vm.$set(_vm.tableQueryParams, "tableQuerySelectNum", $$v);
      },
      expression: "tableQueryParams.tableQuerySelectNum"
    }
  })], 1), _c('div', {
    staticClass: "mt-3 d-flex align-items-center"
  }, [_vm._v(" 選擇日期 : "), _c('date-picker', {
    staticClass: "ml-2 mr-3",
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "placeholder": "請選擇日期",
      "format": "YYYY-MM-DD",
      "value-type": "format",
      "type": "date",
      "disabled-date": _vm.disabledDateAfterToday
    },
    model: {
      value: _vm.tableQueryParams.tableQueryDate,
      callback: function callback($$v) {
        _vm.$set(_vm.tableQueryParams, "tableQueryDate", $$v);
      },
      expression: "tableQueryParams.tableQueryDate"
    }
  })], 1), _c('button', {
    staticClass: "confirmButton mt-3",
    on: {
      "click": _vm.confirmSearch
    }
  }, [_vm.tableLoading ? _c('b-spinner', {
    staticClass: "m-1 mx-2",
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm.$t("deviceHistory.confirm")))])], 1)])]), _c('div', {
    staticClass: "borderColor"
  }, [_c('div', {
    staticClass: "text-center d-flex flex-column titleBackground"
  }, [_c('div', {
    staticClass: "highcharts-title mt-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("deviceHistory.specifiedINVInfoPerMin")) + ": " + _vm._s(_vm.tableTitle) + " ")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm._v(" 顯示筆數: "), _c('b-form-select', {
    staticClass: "ml-2 mr-3",
    staticStyle: {
      "width": "80px"
    },
    attrs: {
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  }), _c('button', {
    staticClass: "confirmButton ml-auto",
    staticStyle: {
      "width": "120px"
    },
    on: {
      "click": _vm.downloadAnalysis
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dataAnalysis.download")) + " ")])], 1)]), _c('b-alert', {
    staticClass: "text-center font-weight-bold my-1",
    attrs: {
      "show": !_vm.tableLoading && !_vm.hasTableData,
      "variant": "secondary"
    }
  }, [_vm._v(" 目前暫無資料或該設備異常，請重新選擇日期或設備 ")]), _vm.tableLoading ? _c('div', {
    staticClass: "text-center m-5"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3",
    attrs: {
      "variant": "secondary"
    }
  }), _c('strong', {
    staticClass: "text-secondary"
  }, [_vm._v("讀取中...")])], 1) : _vm._e(), !_vm.tableLoading && _vm.hasTableData ? _c('b-table', {
    staticClass: "tableStyle bTableSticky",
    attrs: {
      "items": _vm.tableData,
      "fields": _vm.tableField,
      "hover": "",
      "head-variant": _vm.tableThColor,
      "dark": _vm.dark,
      "responsive": true,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-icon-left": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "tbody-transition-props": _vm.transProps,
      "primary-key": "時間",
      "sticky-header": ""
    }
  }) : _vm._e()], 1), _c('b-pagination', {
    staticClass: "mt-2 mb-4",
    attrs: {
      "per-page": _vm.perPage,
      "total-rows": _vm.rows
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };